import React from 'react';
import '../../css/Popup.css';

const Popup = ({ isVisible, closePopup, submitRequest }) => {
  return (
    isVisible && (
      <div className="popup-container">
        <div className="popup-content">
          <h2>Request Inventory</h2>
          <form onSubmit={submitRequest}>
            <div className="input-container">
              <label htmlFor="itemName" className="input-label">Item Name:</label>
              <input type="text" id="itemName" className="input-field" required />
            </div>
            <div className="input-container">
              <label htmlFor="itemQuantity" className="input-label">Quantity:</label>
              <input type="number" id="itemQuantity" className="input-field" required />
            </div>
            <div className="input-container">
              <label htmlFor="itemUoM" className="input-label">Unit of Measurement:</label>
              <input type="text" id="itemUoM" className="input-field" required />
            </div>
            <div className='d-flex gap-2 justify-content-center'>
              <button className='btn custom_btn btn-primary' type="submit">Submit</button>
              <button className='btn custom_btn' style={{border:'1px solid grey'}} type="button" onClick={closePopup}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default Popup;
