import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { makeStyles } from '@mui/styles';
import TxModal from "../admin/TxModal";
import {
  Toast,
  ToastContainer as ToastContainerBootstrap,
} from "react-bootstrap";
import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import Popup from "../home/Popup";
import Footer from "../footer/Footer";
import sp_1_2 from "../../images/3.png";
import watch from "../../images/4.png";
import hammer from "../../images/5.png";
import logs from "../../images/6.png";
import truck from "../../images/truck.PNG";
import "../../css/style.css";
import "../../css/search.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const useStyles = makeStyles(() => ({
  textFieldContainer: {
    border: "none !important",
  },
  textFieldInput: {
    [`& input`]: {
      border: "none !important",
    },
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
  },
}));

const SearchProduct = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useLocation();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  console.log(state);
  const [selected, setSelected] = useState("");
  const [showTxModal, setShowTxModal] = useState(false);
  const [txModalType, setTxModalType] = useState();
  const [txTitle, setTxTitle] = useState("");
  const [txMsg, setTxMsg] = useState("");
  const [txData, setTxData] = useState();
  const [showAddedToWishListToast, setShowAddedToWishListToast] =
    useState(false);
  const [loginFlag, setLoginFlag] = useState(true);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [items, setItems] = useState(state);
  const [favourites, setFavourites] = useState([]);
  const [values, setValues] = useState([]);
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState('All');
  const [count, setCount] = useState(0);
  const [product, setProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [lots, setLots] = useState([]);
  const navigate = useNavigate();
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const pathname = window.location.pathname;

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  useEffect(() => {
    if (pathname) {
      setSelected(pathname?.split("/")[2].replace(/%20/gi, " "));
    } else setSelected("All Categories");
  }, [pathname]);
  useEffect(() => {
    setIsLoading(true);
    if (selected || items) {
      axios
        .post("/product_search", {
          category: selected,
          name: items,
        })
        .then((response) => {
          setIsLoading(false);
          setProduct(response.data.items);
          console.log(response.data.items);
          setLots(response.data.lots)
          setCount(response.data.items.length + response.data.lots.length);
          console.log(response.data);
        })
        .catch((error) => console.log(error));
        setIsLoading(false);
    }
  }, [selected, items]);
  useEffect(() => {
    axios
      .get("/get_categories")
      .then((response) => {
        const categories = response.data.map(
          (category) => category.categoryName
        );
        setValues(categories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  function handleChange(event) {
    setSelected(event.target.value);
  }
  const openRatingModal = (product) => {
    setCurrentProduct(product);
    setShowModal(true);
  };

  const isValidImage = (image) => 
  {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    return image && typeof image === 'string' && imageExtensions.test(image);
  }

  const handleRatingSubmit = () => {
    // TODO: API call to /give_rating with { product: currentProduct, rating, feedback }
    setShowModal(false);
  };
 
  function handleSearch(event) {
    setItems(event.target.value);
  }
  function handleClick(product, type) {
    console.log("TYPE PAGE", type)

    if (!product["Seller Name"]) {
      const newProd = product;
      newProd["Seller Name"] = "*****";
      setProduct(newProd);
    }
    window.scrollTo(0, 0);
    const id = type === "product" ? product.itemId : product.lotId;
    navigate(`/product-detail/${type}/${id}`, { state: { product, type } })
  }
  const openPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const submitRequest = async (e) => {
    e.preventDefault();
    closePopup();

    const itemName = e.target.itemName.value;
    const itemQuantity = e.target.itemQuantity.value;
    try {
      const response = await axios.post(
        "/send_request_mail",
        {
          itemName,
          itemQuantity,
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const addToWishList = (id,price,type) => {
    if (!currentUser) {
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before adding to wishlist`);
      //setTxData(tableMeta.rowData[0]);
      setShowTxModal(true);
    } else {
      if (type === "product") {
        const objToSend = {
          userId: currentUser.id,
          price: price,
          quantity: 1,
          itemId: id,
          type: type
        };
        axios
          .post("/add_to_wishlist", objToSend)
          .then((response) => {
            if (response.status === 202) {
              toast.error("Quantity exceeds the available quantity");
            } else if (response.status === 200) {
              setShowAddedToWishListToast(true);
            }
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });


      }
      else if (type === "lot") {
        const objToSend = {
          userId: currentUser.id,
          price: price,
          quantity: 1,
          lotId: id,
          type: type
        };
        axios
          .post("/add_to_wishlist", objToSend)
          .then((response) => {
            if (response.status === 202) {
              toast.error("Lot already exists in the wishlist");
            } else if (response.status === 200) {
              setShowAddedToWishListToast(true);
            }
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });

      }
    }
  };
  const reveal = async () => {
    if (loginFlag === true) {
      axios
        .post("/clear_cart", { userId: currentUser.id })
        .then((response) => {
          setShowTxModal(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate("/admin/login");
    }
  };
  return (
    <div className="search_product">
      <Header logOut={props.logOut} />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

<Card className="sp-1" style={{ height: "50%" }}>
    <Row className="app_hero" style={{ height: "50%" }}> 
        <Col xs={12} md={7} style={{ height: "50%" }}> 
            
            <Row className="search_field_row" style={{ maxHeight: "20%", overflow: "hidden" }}> 
                <Col xs={12} className="bg-1-sub-2 p-0 search_page_header" style={{ padding: "0%" }}>
                <div className="search-bar">
                  <div className="form-group" style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control searchPageInput"
                      placeholder="Search"
                      value={items}
                      onChange={handleSearch}
                    />
                  </div>

                  <div className="selectContainer">
                    <select
                      className="form-control searchPageDropdown"
                      onChange={handleChange}
                      value={selected}
                    >
                      {values.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>

                </div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    openPopup();
                  }}
                  style={{
                    marginLeft: "10px",
                    display: "block",
                    marginTop: "5px",
                    color: "white",
                  }}
                >
                  Couldn't find what you were looking for? Ask sellers.
                </a>
                </Col>
            </Row>

            
            <Row className="sp-1-5" style={{ maxHeight: "80%", overflow: "auto" }}> 
                <Col xs={12} className="sp-1-sub-5">
                    {count} items found under <span style={{ textTransform: "uppercase" }}>{selected}</span>
                </Col>
            </Row>
        </Col>


        <Col xs={6} md={3} style={{ height: "50%" }}>
            <img className="sp-1-6" src={sp_1_2} alt="" style={{ minHeight: "50%", width: "auto" }} /> 
        </Col>
    </Row>
</Card>



      <div className="app_container container">
        {/* <section className="carousel_seciton">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            renderDotsOutside={true}
          >
            <div className="dispatch_items">
              <Card className="discount_card w-100">
                <div className="discount_text" style={{ padding: "0.8em" }}>
                  <h2 style={{ color: "#E81C45", fontWeight: "bold" }}>
                    SAVE UPTO
                  </h2>
                  <h1 style={{ color: "#306F7B", fontWeight: "bold" }}>30%</h1>
                </div>
                <div className="homepage">
                  <img loading="lazy"  className="card_image" src={watch} alt="watch" />
                </div>
                <div
                  className="reedMore_div"
                  style={{ paddingBottom: "1em", paddingLeft: "1em" }}
                >
                  <button type="button" className="read-more">
                    Read More
                  </button>
                </div>
              </Card>
            </div>
            <div className="dispatch_items">
              <Card className="discount_card w-100">
                <div className="discount_text" style={{ padding: "0.8em" }}>
                  <h2 style={{ color: "#E81C45", fontWeight: "bold" }}>
                    SAVE UPTO
                  </h2>
                  <h1 style={{ color: "#306F7B", fontWeight: "bold" }}>30%</h1>
                </div>
                <div className="homepage">
                  <img loading="lazy"  className="card_image" src={hammer} alt="hammer" />
                </div>
                <div
                  className="reedMore_div"
                  style={{ paddingBottom: "1em", paddingLeft: "1em" }}
                >
                  <button type="button" className="read-more">
                    Read More
                  </button>
                </div>
              </Card>
            </div>
            <div className="dispatch_items">
              <Card className="discount_card w-100">
                <div className="discount_text" style={{ padding: "0.8em" }}>
                  <h2 style={{ color: "#E81C45", fontWeight: "bold" }}>
                    SAVE UPTO
                  </h2>
                  <h1 style={{ color: "#306F7B", fontWeight: "bold" }}>30%</h1>
                </div>
                <div className="homepage">
                  <img loading="lazy"  className="card_image" src={logs} alt="logs" />
                </div>
                <div
                  className="reedMore_div"
                  style={{ paddingBottom: "1em", paddingLeft: "1em" }}
                >
                  <button type="button" className="read-more">
                    Read More
                  </button>
                </div>
              </Card>
            </div>
          </Carousel>
        </section> */}
        {/* <div className='product_conveyance'>
                    <div style={{ display: 'inline-block' }}>
                        <div className="truckRowImage">
                            <img loading="lazy"  src={truck} width={50} height={50} alt="" />
                        </div>
                        <div className="truckRowText">
                            <span className="font-bold text-gray-500 text-[15px], mb-[0]">Lorem Ipsum</span><br />
                            <label className="text-gray-500 text-[12px] mb-[0]">Lorem ipsum dolor lorem ispsum dolor</label>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <div className="truckRowImage">
                            <img loading="lazy"  src={truck} width={50} height={50} alt="" />
                        </div>
                        <div className="truckRowText">
                            <span className="font-bold text-gray-500 text-[15px], mb-[0]">Lorem Ipsum</span><br />
                            <label className="text-gray-500 text-[12px] mb-[0]">Lorem ipsum dolor lorem ispsum dolor</label>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <div className="truckRowImage">
                            <img loading="lazy"  src={truck} width={50} height={50} alt="" />
                        </div>
                        <div className="truckRowText">
                            <span className="font-bold text-gray-500 text-[15px], mb-[0]">Lorem Ipsum</span><br />
                            <label className="text-gray-500 text-[12px] mb-[0]">Lorem ipsum dolor lorem ispsum dolor</label>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <div className="truckRowImage">
                            <img loading="lazy"  src={truck} width={50} height={50} alt="" />
                        </div>
                        <div className="truckRowText">
                            <span className="font-bold text-gray-500 text-[15px], mb-[0]">Lorem Ipsum</span><br />
                            <label className="text-gray-500 text-[12px] mb-[0]">Lorem ipsum dolor lorem ispsum dolor</label>
                        </div>
                    </div>
                </div> */}
        {/* <br />
        <br />
        <hr /> */}

        {/* <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
                    <div className="flex flex-row space-x-2">
                        <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /> </svg>
                        </div>
                        <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                            <svg style={{ color: 'rgb(78, 77, 70)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill="#4e4d46"></path> </svg>
                        </div>
                    </div>
                </div> */}

        {/* <h1 className="related_products_heading text-gray-600 font-bold text-[25px] inline-block float-left mt-[13px]"> */}
        <div className="d-flex justify-content-center">
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn custom_btn ${selectedTab === 'All' ? 'btn-primary' : 'btn-light'}`}
              onClick={() => setSelectedTab('All')}
            >
              All
            </button>
            <button
              type="button"
              className={`btn custom_btn ${selectedTab === 'Items' ? 'btn-primary' : 'btn-light'}`}
              onClick={() => setSelectedTab('Items')}
            >
              Items
            </button>
            <button
              type="button"
              className={`btn custom_btn ${selectedTab === 'Lots' ? 'btn-primary' : 'btn-light'}`}
              onClick={() => setSelectedTab('Lots')}
            >
              Lots
            </button>
          </div>
        </div>
        <br />
        {(selectedTab === 'All') && (
          <div className="row products_row">
            {
              isLoading ? (
                <div
                  className="d-flex justify-content-center"
                >
                  <br />
                  <div className="spinner-border text-info" role="status"></div>
                </div>
              ) : (
                <>
                  {product?.map((product, index) => (
                    <div
                      key={index}
                      className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
                    >
                      <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                      <figure
    style={{ margin: "0", height: "200px", cursor: "pointer" }}
    onClick={() => handleClick(product, 'product')}
>
    <img loading="lazy" 
        src={`${isValidImage(product?.itemImage) || require("../../images/no-image.jpg")}`}
        alt=""
        style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
        }}
    />
</figure>

                        <div className="card-body">
                        <div className="rating rating-sm space-x-2">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <input
            key={i}
            type="radio"
            name={`rating-${index}`}
            style={{ cursor: 'default' }}
            disabled
            className={product.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}
          />
        );
      })}
    </div>
                          {/* <div className="rating rating-sm space-x-2" >
                            <input
                              type="radio"
                              name={`rating-${index}`}
                              disabled
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name={`rating-${index}`}
                              disabled
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name={`rating-${index}`}
                              disabled
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name={`rating-${index}`}
                              disabled
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name={`rating-${index}`}
                              disabled
                              className="mask mask-star-2 bg-orange-400"
                            />
                          </div> */}
                          <div
                            className="flex flex-row"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2 className="text-[14px] mt-1">{product?.itemName}</h2>
                            <div className="rating w-30px" style={{ float: "right" }}>
                              <input
                                type="radio"
                                name={`rating-${index}`}
                                
                                className="whishlist_icon hover:bg-red-600"
                                style={{
                                  fontWeight: `${favourites.indexOf(product) > -1 ? "900" : ""
                                    }`,
                                }}
                                onClick={() => addToWishList(product?.itemId, product.itemPrice, 'product')}
                              />
                            </div>
                          </div>
                          <hr style={{ margin: "0" }} />
                        </div>
                        
                        <div className="product_price" style={{ padding: "0 1em" }}>
                          <p className="text-[14px]">{product?.itemDesc}</p>
                          <br />
                          <h1
                            className="text-[20px] font-bold mb-3"
                            style={{ color: "#0d6efd" }}
                          >
                            USD {product?.itemPrice}
                          </h1>
                        </div>
                      </div>
                    </div>
                  ))}
                  {lots.map((lot, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
              >
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick(lot, 'lot')}
                  >
                    <img loading="lazy" 
                      src={`${lot.lotImage}`}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                  <div className="rating rating-sm space-x-2">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <input
            key={i}
            type="radio"
            name={`rating-${index}`}
            style={{ cursor: 'default' }}
            disabled
            className={lot.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}
          />
        );
      })}
    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">{lot.lotName}</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name={`rating-${index}`}
                          
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf(lot) > -1 ? "900" : ""
                              }`,
                          }}
                          onClick={() => addToWishList(lot?.lotId, lot.lotPrice, 'lot')}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">{lot.lotDesc}</p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      USD {lot.lotPrice}
                    </h1>
                  </div>
                </div>
              </div>
            ))}
                </>
              )
            }
          </div>

        )}
        {(selectedTab === 'Items') && (
          <div className="row products_row">
            {
              !product ? (
                <div
                  className="d-flex justify-content-center"
                >
                  <br />
                  <div className="spinner-border text-info" role="status"></div>
                </div>
              ) : (
                <>
                  {product?.map((product, index) => (
                    <div
                      key={index}
                      className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
                    >
                      <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                        <figure
                          style={{ margin: "0", height: "200px", cursor: "pointer" }}
                          onClick={() => handleClick(product, 'product')}
                        >
                          <img loading="lazy" 
                            src={`${isValidImage(product?.itemImage) || require("../../images/no-image.jpg")}`}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </figure>
                        <div className="card-body">
                        <div className="rating rating-sm space-x-2">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <input
            key={i}
            type="radio"
            name={`rating-${index}`}
            style={{ cursor: 'default' }}
            disabled
            className={product.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}
          />
        );
      })}
    </div>
                          <div
                            className="flex flex-row"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2 className="text-[14px] mt-1">{product?.itemName}</h2>
                            <div className="rating w-30px" style={{ float: "right" }}>
                              <input
                                type="radio"
                                name={`rating-${index}`}
                                
                                className="whishlist_icon hover:bg-red-600"
                                style={{
                                  fontWeight: `${favourites.indexOf(product) > -1 ? "900" : ""
                                    }`,
                                }}
                                onClick={() => addToWishList(product?.itemId, product.itemPrice, 'product')}
                              />
                            </div>
                          </div>
                          <hr style={{ margin: "0" }} />
                        </div>
                        <div className="product_price" style={{ padding: "0 1em" }}>
                          <p className="text-[14px]">{product?.itemDesc}</p>
                          <br />
                          <h1
                            className="text-[20px] font-bold mb-3"
                            style={{ color: "#0d6efd" }}
                          >
                            USD {product?.itemPrice}
                          </h1>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )
            }
          </div>

        )}
        {(selectedTab === 'Lots') && (
          <div className="row products_row">

            {lots.map((lot, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
              >
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick(lot, 'lot')}
                  >
                    <img loading="lazy" 
                      src={`${lot.lotImage}`}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                  <div className="rating rating-sm space-x-2">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <input
            key={i}
            type="radio"
            name={`rating-${index}`}
            style={{ cursor: 'default' }}
            disabled
            className={lot.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}
          />
        );
      })}
    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">{lot.lotName}</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name={`rating-${index}`}
                          
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf(lot) > -1 ? "900" : ""
                              }`,
                          }}
                          onClick={() => addToWishList(lot?.lotId, lot.lotPrice, 'lot')}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">{lot.lotDesc}</p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      USD {lot.lotPrice}
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* {selected === "Packaging Materials" ? (
                    <div className="row products_row">
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                                <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("Packaging Materials")}>
                                    <img loading="lazy"  src={require('../../images/image001.png')} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </figure>
                                <div className="card-body">
                                    <div className="rating rating-sm space-x-2">
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                    </div>
                                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                                        <h2 className="text-[14px] mt-1">Wood</h2>
                                        <div className="rating w-30px" style={{ float: 'right' }}>
                                            <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("Wood") > -1 ? '900' : ''}` }} onClick={() => addToWishList("Wood")} />
                                        </div>
                                    </div>
                                    <hr style={{ margin: '0' }} />
                                </div>
                                <div className='product_price' style={{ padding: '0 1em' }}>
                                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                                    <br />
                                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : selected === "Raw Materials" ? (
                    <div className="row products_row">
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                            <div className="product_card card card-compact w-100 rounded-none bg-base-100 shadow-xl">
                                <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("Raw Materials")}>
                                    <img loading="lazy"  src={require('../../images/image002.jpg')} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </figure>
                                <div className="card-body">
                                    <div className="rating rating-sm space-x-2">
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                    </div>
                                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                                        <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                                        <div className="rating w-30px" style={{ float: 'right' }}>
                                            <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("ISO Propyl Alcohol") > -1 ? '900' : ''}` }} onClick={() => addToWishList("ISO Propyl Alcohol")} />
                                        </div>
                                    </div>
                                    <hr style={{ margin: '0' }} />
                                </div>
                                <div className='product_price' style={{ padding: '0 1em' }}>
                                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                                    <br />
                                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                ) : (
                    <div className="row products_row">
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                                <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("Raw Materials")}>
                                    <img loading="lazy"  src={require('../../images/image002.jpg')} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </figure>
                                <div className="card-body">
                                    <div className="rating rating-sm space-x-2">
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                    </div>
                                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                                        <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                                        <div className="rating w-30px" style={{ float: 'right' }}>
                                            <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("ISO Propyl Alcohol") > -1 ? '900' : ''}` }} onClick={() => addToWishList("ISO Propyl Alcohol")} />
                                        </div>
                                    </div>
                                    <hr style={{ margin: '0' }} />
                                </div>
                                <div className='product_price' style={{ padding: '0 1em' }}>
                                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                                    <br />
                                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                                <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("Packaging Materials")}>
                                    <img loading="lazy"  src={require('../../images/image001.png')} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </figure>
                                <div className="card-body">
                                    <div className="rating rating-sm space-x-2">
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                    </div>
                                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                                        <h2 className="text-[14px] mt-1">Wood</h2>
                                        <div className="rating w-30px" style={{ float: 'right' }}>
                                            <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("Wood") > -1 ? '900' : ''}` }} onClick={() => addToWishList("Wood")} />
                                        </div>
                                    </div>
                                    <hr style={{ margin: '0' }} />
                                </div>
                                <div className='product_price' style={{ padding: '0 1em' }}>
                                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                                    <br />
                                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                                <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("")}>
                                    <img loading="lazy"  src="https://placeimg.com/400/225/arch" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </figure>
                                <div className="card-body">
                                    <div className="rating rating-sm space-x-2">
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                        <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                                    </div>
                                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                                        <h2 className="text-[14px] mt-1">Sample Product 3</h2>
                                        <div className="rating w-30px" style={{ float: 'right' }}>
                                            <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("Sample Product 1") > -1 ? '900' : ''}` }} onClick={() => addToWishList("Sample Product 3")} />
                                        </div>
                                    </div>
                                    <hr style={{ margin: '0' }} />
                                </div>
                                <div className='product_price' style={{ padding: '0 1em' }}>
                                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                                    <br />
                                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}

        <Footer />
      </div>
      <TxModal
          title={txTitle}
          msg={txMsg}
          show={showTxModal}
          setShow={setShowTxModal}
          confirmTx={reveal}
          type={txModalType}
          login={true}
        />
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
        <Modal.Title>
  Rate {currentProduct?.itemName ? currentProduct.itemName : currentProduct?.lotName}
</Modal.Title>

        </Modal.Header>
        
        <Modal.Body>
          {/* Star Rating */}
          <div className="star-rating">
            {[...Array(5)].map((star, i) => {
              const ratingValue = i + 1;
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="rating"
                    value={ratingValue}
                    hidden
                    onClick={() => setRating(ratingValue)}
                  />
                  <span className={rating >= ratingValue ? "star filled" : "star"}>&#9733;</span>
                </label>
              );
            })}
          </div>
          <textarea value={feedback} placeholder="Enter Feedback" onChange={e => setFeedback(e.target.value)} className="form-control mt-2" />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleRatingSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      <Popup
        isVisible={isPopupVisible}
        closePopup={closePopup}
        submitRequest={submitRequest}
      />
      <ToastContainerBootstrap className="showToast">
        {/* <Toast
          autohide={true}
          show={showAddedToCartToast}
          onClose={() => setShowAddedToCartToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto">Cart</strong>
            <small>1 second ago</small>
          </Toast.Header>
          <Toast.Body>Product added to cart.</Toast.Body>
        </Toast> */}

        <Toast
          autohide={true}
          show={showAddedToWishListToast}
          onClose={() => setShowAddedToWishListToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto">WishList</strong>
            <small>1 second ago</small>
          </Toast.Header>
          <Toast.Body onClick={() => navigate("/negotiate-cart")}>
            Product added to wishlist.
          </Toast.Body>
        </Toast>
      </ToastContainerBootstrap>
    </div>
  );
};

export default SearchProduct;
