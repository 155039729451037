import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BsFunnel } from 'react-icons/bs';
import { DataGrid, GridContextProvider } from '@mui/x-data-grid';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Stack from '@mui/material/Stack';
import DatePicker from 'react-datepicker';
import TxModal from './TxModal';
import Header from './Header';
import Sidebar from './Sidebar';
import CustomDownloader from './CustomDownloader';
import moment from 'moment';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../../css/style.css';
import '../../css/inventory.css';

const InventoryList = (props) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const id = currentUser.id;
  const [columns, setColumns] = useState([]);
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const logoUrl = localStorage.getItem("logoUrl");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [csvHeaders, setCSVHeaders] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [deletion, setDeletion] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showTxModal, setShowTxModal] = useState(false);
  const [txModalType, setTxModalType] = useState();
  const [txTitle, setTxTitle] = useState("");
  const [txMsg, setTxMsg] = useState("");
  const [txData, setTxData] = useState();
  const [searchColumn, setSearchColumn] = useState(null);
  const [editRowsModel, setEditRowsModel] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState();
  const [selected, setSelected] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  let navigate = useNavigate();
  const { state } = useLocation();
  const styles = {
    container: {
      height: "500px", // set the height of the container
    },
  };
  const buttonStyle = {
    backgroundColor: "#0d70a5", // Bootstrap primary blue
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    fontSize: "0.875rem",
    lineHeight: "1.5",
    marginLeft: "5px",
    marginRight: "5px",
  };
  const buttonStyle2 = {
    backgroundColor: "#4ca9d6", // Lighter blue color
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    fontSize: "0.875rem",
    lineHeight: "1.5",
    marginLeft: "5px",
    marginRight: "5px",
  };
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const quantityStyle = {
    width: "40px", // Adjust this value as per your requirement
    textAlign: "center",
    marginRight: "5px",
    marginLeft: "5px",
    backgroundColor: "white",
  };

  useEffect(() => {
    setIsLoading(true);
    const tempColumns = [
      {
        field: "InventoryNumber",
        headerName: "Inv. #",
        flex: 1,
        align: "right",
        width: 75,
        minWidth: 75,
      },
      {
        field: "Name",
        headerName: "Name",
        flex: 1,
        width: 100,
        minWidth: 100,
      },
      {
        field: "Description",
        headerName: "Description",
        flex: 1,
        valueGetter: (params) => params.row.Description || "",
        cellClassName: "wrap-text",
        width: 150,
        minWidth: 150,
      },
      {
        field: "OnHandInventory",
        headerName: "Total Quantity",
        flex: 1,
        align: "right",
        width: 100,
        minWidth: 100,
      },

      {
        field: "itemPrice",
        headerName: "Price Per Item",
        flex: 1,
        align: "right",
        valueGetter: (params) =>
          params.row.itemPrice.toFixed(2) + " " + currentUser.currency,
        width: 100,
        minWidth: 100,
      },

      {
        field: "TotalPrice",
        headerName: "Total Price Per Item",
        flex: 1,
        align: "right",
        valueGetter: (params) =>
          params.row.TotalPrice.toFixed(2) + " " + currentUser.currency,
        width: 100,
        minWidth: 100,
      },

      {
        field: "Quantity",
        headerName: "Min. Buying Quantity",
        flex: 1,
        align: "right",
        width: 150,
        minWidth: 150,
      },
      {
        field: "alreadyPublished",
        headerName: "Published Quantity",
        flex: 1,
        align: "right",
        width: 150,
        minWidth: 150,
      },
      {
        field: "lotQuantity",
        headerName: "Quantity in Lots",
        flex: 1,
        align: "right",
        width: 150,
        minWidth: 150,
      },
      {
        field: "RemainingQuantity",
        headerName: "Available Quantity",
        flex: 1,
        align: "right",
        width: 150,
        minWidth: 150,
      },

      {
        field: "publishedQuantity",
        headerName: "Quantity To Publish",
        flex: 2,
        editable: false,
        align: "center",
        width: 150,
        minWidth: 150,

        renderCell: (params) => (
          <div style={containerStyle}>
            <button
              style={buttonStyle2}
              onClick={(e) => handleDecrease(params, e)}
            >
              {" "}
              -{" "}
            </button>
            <input
              type="number"
              min="0"
              value={params.getValue(params.id, "publishedQuantity") || ""}
              placeholder="Enter"
              onChange={(e) => handleChange(params, e)}
              style={quantityStyle}
            />
            <button
              style={buttonStyle}
              onClick={(e) => handleIncrease(params, e)}
            >
              {" "}
              +{" "}
            </button>
          </div>
        ),
      },
    ];
    tempColumns.push({
      field: "Actions",
      headerName: "Actions",
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <div>
          <FontAwesomeIcon
            icon={faTrash}
            className="mr-5"
            onClick={() => {
              handleDelete(params.row.InventoryNumber, params.row.Name);
            }}
          />
          <FontAwesomeIcon
            icon={faEdit}
            className="mr-5"
            onClick={() => {
              navigate("/admin/item-listing/update-item", {
                state: params.row,
              });
            }}
          />
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => {
              navigate("/admin/item-listing/view-item", {
                state: params.row,
              });
            }}
          />
        </div>
      ),
    });

    axios
      .get(`/items/${id}`)
      .then((res) => {
        let data = res.data.items;
        console.log(data);

        data.forEach((row) => {
          if (row.ExpiryDate) {
            row.ExpiryDate = moment(new Date(row.ExpiryDate)).format(
              "DD MMM YYYY"
            );
            row.Shipping = moment(new Date(row.ExpiryDate)).format(
              "DD MMM YYYY"
            );
            //row.publishedQuantity = parseInt(row.PublishedQuantity);
          }
        });
        setIsLoading(false);
        setColumns(tempColumns);
        setRows(data);
        setFilteredRows(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditRowModelChange = (newModel) => {
    setEditRowsModel(newModel);
  }

  const handleIncrease = (params, event) => {
    event.stopPropagation();
    console.log("Clicked increase for params.id:", params.id);
    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, publishedQuantity: row.publishedQuantity + 1 };
        } else {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, publishedQuantity: row.publishedQuantity + 1 };
        } else {
          return row;
        }
      });
    });
  }

  const handleDecrease = (params, event) => {
    event.stopPropagation();
    console.log("Clicked decrease for params.id:", params.id);
    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id && row.publishedQuantity > 0) {
          return { ...row, publishedQuantity: row.publishedQuantity - 1 };
        } else {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id && row.publishedQuantity > 0) {
          return { ...row, publishedQuantity: row.publishedQuantity - 1 };
        } else {
          return row;
        }
      });
    });
  }

  const handleChange = (params, event) => {
    event.stopPropagation();

    const inputVal = event.target.value;
    let newQuantity = parseInt(inputVal, 10);

    if (inputVal === "") {
      newQuantity = 0;
    }

    if (isNaN(newQuantity) || newQuantity < 0) return; // Ensure valid and non-negative input

    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, publishedQuantity: newQuantity };
        } else {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, publishedQuantity: newQuantity };
        } else {
          return row;
        }
      });
    });
  }

  const handleQuantityChange = (inventoryNumber, value) => {
    const rowIndex = rows.findIndex(
      (row) => row.InventoryNumber === inventoryNumber
    );

    // Create a new copy of the row object with the updated BidChoice property
    const updatedRow = {
      ...rows[rowIndex],
      publishedQuantity: value,
    };

    // Create a new array with the updated row and the other rows
    const updatedRows = [
      ...rows.slice(0, rowIndex),
      updatedRow,
      ...rows.slice(rowIndex + 1),
    ];

    // Update the state with the new rows array
    setRows(updatedRows);
  }

  const handleRowSelection = (selectedRows) => {
    setSelected(selectedRows);
    console.log(selectedRows);
  }
  
  const handleEditCellChange = ({ id, field, props }) => {
    console.log("INSIDE CHANGE");
    console.log(id);
    console.log(field);
    console.log(props);
    const updatedRows = rows.map((row) => {
      if (row.InventoryNumber === id) {
        return { ...row, [field]: props.value };
      }
      return row;
    });
    console.log(updatedRows);
    setRows(updatedRows);
  }
  
  const handleDelete = async (id, name) => {
    setDelId(id);
    setTxModalType(1);
    setTxTitle(`Deletion Confirmation`);
    setTxMsg(`Are you sure you want to delete ${name}?`);
    //setTxData(tableMeta.rowData[0]);
    setShowTxModal(true);
  }

  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  }

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  }

  const handleDropdownClick = (event, search) => {
    event.preventDefault();
    setSearchColumn(search);
  }
  
  const reveal = async () => {
    setIsLoading(true);
    axios
      .delete(`/delete_item/${delId}`)
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          console.log("IN DEL");
          const updatedRows = rows.filter(
            (row) => row.InventoryNumber !== delId
          );
          setIsLoading(false);
          setRows(updatedRows);
        }
        setShowTxModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleBidChange = (inventoryNumber, newBidChoice) => {
    console.log("INVENTORY NUMBER", inventoryNumber);
    console.log("BID CHOICE", newBidChoice);
    axios
      .post("/update_item_bid_choice", {
        itemId: inventoryNumber,
        bidChoice: newBidChoice,
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          if (newBidChoice === "Close Bidding") {
            setSelectedItemId(inventoryNumber);
            handleShow();
          }
          toast.success("Item Bid Updated");
          console.log("ROWS AFTER API CALL", rows);
          const rowIndex = rows.findIndex(
            (row) => row.InventoryNumber === inventoryNumber
          );
          console.log("ROW INDEX", rowIndex);

          // Create a new copy of the row object with the updated BidChoice property and the required id property
          const updatedRow = {
            ...rows[rowIndex],
            id: rows[rowIndex].InventoryNumber,
            BidChoice: newBidChoice,
          };

          // Create a new array with the updated row and the other rows
          const updatedRows = [
            ...rows.slice(0, rowIndex),
            updatedRow,
            ...rows.slice(rowIndex + 1),
          ];

          // Update the state with the new rows array
          setRows([...updatedRows]);

          // If the new bid choice is 'Close Bidding', open the dialog
        }
        // Handle successful response here
      })
      .catch((error) => {
        console.error(error);
        // Handle error response here
      });
  }

  const submitExpiryDate = () => {
    axios
      .post("/update_item_expiry", {
        itemId: selectedItemId,
        expiry: expiryDate,
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle error response here
      });
    handleClose();
  }

  const handleInputChange = (event) => {
    let userInput = event.target.value;
    userInput = userInput.trim();

    if (userInput === "") {
      setFilteredRows(rows);
    } else {
      if (searchColumn) {
        if (searchColumn === "Name") {
          const result = rows.filter((search) =>
            search?.Name?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
          setFilteredRows(result);
        } else if (searchColumn === "Description") {
          const result = rows.filter((search) =>
            search?.Description?.toLowerCase()?.includes(
              userInput?.toLowerCase()
            )
          );
          setFilteredRows(result);
        } else if (searchColumn === "Price") {
          const result = rows.filter(
            (row) => row.itemPrice.toString() === userInput
          );
          setFilteredRows(result);
        }
      } else {
        const result = rows.filter(
          (search) =>
            search?.Name?.toLowerCase()?.includes(userInput?.toLowerCase()) ||
            search?.Description?.toLowerCase()?.includes(
              userInput?.toLowerCase()
            )
        );
        setFilteredRows(result);
      }
    }
  }

  const handleCSVClick = () => {
    let headers = [];

    for (var i = 0; i < columns.length; i++) {
      headers.push({ label: columns[i].name, key: Object.keys(rows[0])[i] });
    }

    setCSVHeaders(headers);
    setCSVData(filteredRows);
  }

  const handleCreateItem = () => {
    navigate("/admin/item-listing/create-item");
  }

  const handlePublishItem = () => {
    if (selected.length === 0) {
      setDeletion(0);
      setShowModal(true);
    } else {
      const itemIds = [];
      const bidChoices = [];
      const publishedQuantities = [];
      let flag = true;

      selected.forEach((selectedIndex) => {
        const selectedRow = rows.find(
          (row) => row.InventoryNumber === selectedIndex
        );
        console.log(selectedRow);
        if (selectedRow.BidChoice !== undefined) {
          const onHandInventory = selectedRow.OnHandInventory;
          const minimumBuyingQuantity = selectedRow.Quantity;
          const remainingQuantity = selectedRow.RemainingQuantity;
          const isPublished = selectedRow.published;
          console.log(selectedRow.publishedQuantity);
          const publishedQuantity = Number(selectedRow.publishedQuantity);
          console.log("INSIDE");
          console.log(publishedQuantity);

          if (isPublished === "Published") {
            if (
              publishedQuantity > 0 &&
              publishedQuantity <= remainingQuantity
            ) {
              itemIds.push(selectedRow.InventoryNumber);
              bidChoices.push(selectedRow.BidChoice);
              publishedQuantities.push(selectedRow.publishedQuantity);
            } else if (
              publishedQuantity === undefined ||
              publishedQuantity <= 0
            ) {
              toast.error("Select a valid quantity to publish");
              flag = false;
            } else if (publishedQuantity > remainingQuantity) {
              toast.error(
                "Published Quantity should be less than or equal to remaining quantity"
              );
              flag = false;
            }
          } else if (isPublished === "Unpublished") {
            if (publishedQuantity === undefined) {
              toast.error("Select quantity to publish");
              flag = false;
            } else if (publishedQuantity < minimumBuyingQuantity) {
              toast.error(
                "Published Quantity should be more than Minimum Buying Quantity"
              );
              flag = false;
            } else if (publishedQuantity > remainingQuantity) {
              toast.error(
                "Published Quantity should be less than or equal to remaining quantity"
              );
              flag = false;
            } else {
              itemIds.push(selectedRow.InventoryNumber);
              bidChoices.push(selectedRow.BidChoice);
              publishedQuantities.push(selectedRow.publishedQuantity);
            }
          }
        } else {
          toast.error("Select a bid choice for the item before publishing!");
          flag = false;
        }
      });

      if (itemIds.length === 0) {
        console.log("No valid items selected for publishing");
        return;
      }
      if (flag === true) {
        setIsLoading(true);
        axios
          .post("/publish_items", {
            item_ids: itemIds,
            bidChoices: bidChoices,
            publishedQuantities: publishedQuantities,
            sellerId: currentUser.id,
          })
          .then((response) => {
            console.log(response.data);
            setIsLoading(false);
            navigate("/admin/item-listing/published-products");
          })
          .catch((error) => {
            console.error(error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              toast.error(error.response.data.error);
              setIsLoading(false);
            } else {
              toast.error("An unexpected error occurred.");
            }
          });
      }
    }
  }
  
  const handleDeleteSelected = () => {
    if (selected.length === 0) {
      setDeletion(1);
      setShowModal(true);
    } else {
      setDelId(null); // single delete ID is nullified
      setTxModalType(1);
      setTxTitle(`Deletion Confirmation`);
      setTxMsg(`Are you sure you want to delete the selected items?`);
      setShowTxModal(true);
    }
  }
  
  const deleteItem = (id) => {
    return axios.delete(`/delete_item/${id}`);
  }

  const deleteSelectedItems = async () => {
    setIsLoading(true);

    // Map over selected InventoryNumbers and create an array of delete promises
    const deletePromises = selected.map((InventoryNumber) =>
      deleteItem(InventoryNumber)
    );

    try {
      // Attempt to delete all selected items concurrently
      await Promise.all(deletePromises);

      // Update rows to only include items that were NOT selected for deletion
      const updatedRows = rows.filter(
        (row) => !selected.includes(row.InventoryNumber)
      );

      setRows(updatedRows);
      setFilteredRows(updatedRows); // Update the state with the new rows
      setSelected([]); // Clear the selected rows after deletion
    } catch (error) {
      console.log(error); // Log any errors encountered during deletion
    }

    setIsLoading(false); // Set loading state to false after deletion
    setShowTxModal(false); // Hide the confirmation modal
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleDeleteItem = async () =>
  {
    setIsLoading(true);
    setShowTxModal(false);

    await axios
    .put("/delete_inventory_items", { items: [delId] })
    .then((response) => 
    {
      setIsLoading(false);

      if (response.status === 200) 
      {
        toast.success(response?.data?.message || "");

        const updatedRows = rows.filter(row => row.InventoryNumber !== delId) || [];
        const updatedFilteredRows = filteredRows.filter(row => row.InventoryNumber !== delId) || [];

        setRows(updatedRows);
        setFilteredRows(updatedFilteredRows);
        setDelId(null);
      }
      else if (response.status === 202)
      {
        toast.error(response?.data?.message || "");
      }
      else
      {
        toast.error("Something went wrong. Please try again later.");
      }
    })
    .catch((error) => 
    {
      console.log("Delete Items: ", error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again later.");
    });
  }

  const handleDeleteItems = async () =>
  {
    setIsLoading(true);
    setShowTxModal(false);

    await axios
    .put("/delete_inventory_items", { items: selected })
    .then((response) => 
    {
      setIsLoading(false);

      if (response.status === 200) 
      {
        toast.success(response?.data?.message || "");
        
        const updatedRows = rows.filter(row => !selected.includes(row.InventoryNumber)) || [];
        const updatedFilteredRows = filteredRows.filter(row => !selected.includes(row.InventoryNumber)) || [];

        setRows(updatedRows);
        setFilteredRows(updatedFilteredRows);
        setSelected([]);
      }
      else if (response.status === 202)
      {
        toast.error(response?.data?.message || "");
      }
      else
      {
        toast.error("Something went wrong. Please try again later.");
      }
    })
    .catch((error) => 
    {
      console.log("Delete Items: ", error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again later.");
    });
  }

  return (
    <div className="inventory_list_page">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${
                  collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>

              <div
                className={`inventory_list_content ${
                  collapseSidebar ? "open_content" : "pinched_content"
                }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}

                {props.message && (
                  <ToastContainer className="messageToast">
                    <Toast
                      onClose={() => {
                        props.setMessage(null);
                      }}
                      delay={3000}
                      autohide
                    >
                      <Toast.Header>
                        <strong className="me-auto">Message</strong>
                        <small>2 secs ago</small>
                      </Toast.Header>
                      <Toast.Body>{props.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                )}

                <div
                  className={`w-100 content_panel ${
                    collapseSidebar ? "slide-in" : "slide-out"
                  }`}
                >
                  <div style={{ height: "100vh" }}>
                    <h3 className="pageName_heading">
                      {localStorage.getItem("logoUrl") && (
                        <img
                          src={localStorage.getItem("logoUrl")}
                          alt="Company Logo"
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />
                      )}
                      Inventory List
                    </h3>

                    <div className="listing row">
                      <div className="col-12 col-md-6 h-[45px] position-relative">
                        <div className="admin_panel_searchbar">
                          <input
                            type={`${
                              searchColumn === "Expiry Date" ? "date" : "text"
                            }`}
                            placeholder={`Search ${
                              searchColumn ? searchColumn : "Inventory"
                            }`}
                            className="input py-[1.5em] px-[3em]"
                            onChange={(event) => handleInputChange(event)}
                          />
                          <button
                            className="filter dropdown-toggle"
                            type="button"
                            id="filterButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <BsFunnel
                              style={{ color: "black", fontSize: "18px" }}
                            />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="filterButton"
                            style={{ zIndex: "1", fontSize: "14px" }}
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                href="#/"
                                onClick={(event) =>
                                  handleDropdownClick(event, "Name")
                                }
                              >
                                Filter By Name
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#/"
                                onClick={(event) =>
                                  handleDropdownClick(event, "Description")
                                }
                              >
                                Filter By Description
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#/"
                                onClick={(event) =>
                                  handleDropdownClick(event, "Price")
                                }
                              >
                                Filter By Price
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* <div className = "btn-group">
                    <button className = "actions dropdown-toggle" type = "button" id = "actionbutton" data-bs-toggle = "dropdown" aria-expanded = "false">
                      ACTIONS
                    </button> 
                    <ul className = "dropdown-menu" aria-labelledby = "actionbutton">
                      <li><a className = "dropdown-item" href = "/#">Filter By Name</a></li>
                    </ul>
                    
                    <button className = "view dropdown-toggle" type = "button" id = "viewButton" data-bs-toggle = "dropdown" aria-expanded = "false">
                      Select View
                    </button>
                    <ul className = "dropdown-menu" aria-labelledby = "viewButton">
                      <li><a className = "dropdown-item" href = "/#">Filter By Name</a></li>
                    </ul>
                  </div> */}
                      </div>
                      <div className="col-12 col-md-6 ">
                        <div className="admin_couple_buttons">
                          <div className="text-center">
                            <button
                              type="button"
                              className="createItemButton"
                              onClick={handleCreateItem}
                            >
                              Create Item
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={styles.container} className="customCard">
                      <GridContextProvider>
                        <DataGrid
                          rows={filteredRows}
                          columns={columns}
                          getRowId={(row) => row.InventoryNumber}
                          onEditCellPropsChange={handleEditCellChange}
                          checkboxSelection={!isLoading}
                          onSelectionModelChange={handleRowSelection}
                          selectionModel={selected}
                          loading={isLoading}
                          rowHeight={40}
                          autoPageSize
                          rowsPerPageOptions={[]}
                          pagination
                          density="compact"
                          disableExtendRowFullWidth={true}
                          disableSelectionOnClick={false}
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                              ? "dataTable_even_row"
                              : "dataTable_odd_row"
                          }
                          components={{
                            NoRowsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                There are no records to display
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                There are no records to display
                              </Stack>
                            ),
                          }}
                        />
                      </GridContextProvider>
                    </div>
                    {filteredRows && filteredRows.length > 0 && (
                      <CustomDownloader
                        rows={filteredRows}
                        title={"Inventory List"}
                      />
                    )}

                    {filteredRows && filteredRows.length > 0 && (
                      <button
                        type="button"
                        className="publish ml-5"
                        onClick={handlePublishItem}
                      >
                        Publish Item(s)
                      </button>
                    )}
                    {filteredRows && filteredRows.length > 0 && (
                      <button
                        type="button"
                        className="publish"
                        onClick={handleDeleteSelected}
                      >
                        Delete Item(s)
                      </button>
                    )}
                    <br />

                    <Modal centered show={showModal} onHide={handleCloseModal}>
                      <Modal.Body>
                        <h1 className="text-slate-600 text-[18px] text-center p-[1em]">
                          Please select one or more items from the table before
                          {deletion === 1 ? " deleting" : " publishing"}
                        </h1>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="warning"
                          onClick={handleCloseModal}
                          style={{
                            margin: "auto",
                            width: "100px",
                            color: "white",
                          }}
                        >
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <TxModal
        title={txTitle}
        msg={txMsg}
        show={showTxModal}
        setShow={setShowTxModal}
        confirmTx={delId ? handleDeleteItem : handleDeleteItems}
        type={txModalType}
        login={false}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Expiry Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            selected={expiryDate}
            onChange={(date) => setExpiryDate(date)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitExpiryDate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InventoryList;