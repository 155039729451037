// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.discount_cards_row{
    justify-content: center;
    grid-gap: 15px 0;
}
.discount_card{
    height: 270px !important;
    width: 100%;
    background-color: #F0EDED;
}

.discount_card .card_image {   
     position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -50%);
    width: 130px;
    height: auto;
    /* margin-top: -8em;
    margin-left: 11.5em;
    margin-bottom: -4.6em;
    ei   width: 63%; */
}

.search-product .logs {
    margin-top: -5em;
    margin-left: 8em;
    margin-bottom: -0.8em;
    width: 70%;
}

.products_row{

    grid-gap: 15px 0;
}
.read-more {
    padding: 5px 10px;
    color: #E81C45;
    border: 2px solid #E81C45;
    font-weight: bold;
}
@media (max-width : 1024px){
    .discount_card .card_image {   
       transform: translate(-40%, -39%);
       width: 90px;
   }
}
.star-rating {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .star {
    font-size: 24px;
    color: lightgray;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .star.filled {
    color: orange;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/search.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,wBAAwB;IACxB,WAAW;IACX,yBAAyB;AAC7B;;AAEA;KACK,kBAAkB;IACnB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,YAAY;IACZ;;;sBAGkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;IACrB,UAAU;AACd;;AAEA;;IAEI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;AACrB;AACA;IACI;OACG,gCAAgC;OAChC,WAAW;GACf;AACH;AACA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf","sourcesContent":["\n.discount_cards_row{\n    justify-content: center;\n    grid-gap: 15px 0;\n}\n.discount_card{\n    height: 270px !important;\n    width: 100%;\n    background-color: #F0EDED;\n}\n\n.discount_card .card_image {   \n     position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-30%, -50%);\n    width: 130px;\n    height: auto;\n    /* margin-top: -8em;\n    margin-left: 11.5em;\n    margin-bottom: -4.6em;\n    ei   width: 63%; */\n}\n\n.search-product .logs {\n    margin-top: -5em;\n    margin-left: 8em;\n    margin-bottom: -0.8em;\n    width: 70%;\n}\n\n.products_row{\n\n    grid-gap: 15px 0;\n}\n.read-more {\n    padding: 5px 10px;\n    color: #E81C45;\n    border: 2px solid #E81C45;\n    font-weight: bold;\n}\n@media (max-width : 1024px){\n    .discount_card .card_image {   \n       transform: translate(-40%, -39%);\n       width: 90px;\n   }\n}\n.star-rating {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n  }\n  \n  .star {\n    font-size: 24px;\n    color: lightgray;\n    cursor: pointer;\n    transition: color 0.2s;\n  }\n  \n  .star.filled {\n    color: orange;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
