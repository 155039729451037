// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .popup-content {
    background-color: white;
    border: 2px solid #007bff;
    border-radius: 5px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: white;
  }
  
  .input-label {
    margin-bottom: 5px;
  }
  .input-field {
    border: 1px solid #007bff;
    border-radius: 4px;
    padding: 5px;
    outline: none;
    background-color: white;
  }
  
  .input-field:focus {
    border-color: #0056b3;
    box-shadow: 0 0 3px #007bff;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/Popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;EACtC;;EAEA;IACE,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;AAEF;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;EACpB;EACA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;IACrB,2BAA2B;EAC7B","sourcesContent":[".popup-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n  \n  .popup-content {\n    background-color: white;\n    border: 2px solid #007bff;\n    border-radius: 5px;\n    padding: 20px;\n    max-width: 400px;\n    width: 90%;\n  }\n  \n  .button-container {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n.input-container {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n    background-color: white;\n  }\n  \n  .input-label {\n    margin-bottom: 5px;\n  }\n  .input-field {\n    border: 1px solid #007bff;\n    border-radius: 4px;\n    padding: 5px;\n    outline: none;\n    background-color: white;\n  }\n  \n  .input-field:focus {\n    border-color: #0056b3;\n    box-shadow: 0 0 3px #007bff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
