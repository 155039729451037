import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import { useNavigate, Navigate } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "./Tooltip";
import { Button } from "react-bootstrap";
import "../../css/style.css";
import "../../css/inventory.css";
import { type } from "@testing-library/user-event/dist/type";

const UpdateItem = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [image, setImage] = useState(require("../../images/no-image.jpg"));
  const [item, setItem] = useState({});
  const [pcount, setPcount] = useState(0);
  const [newLabels, setNewLabels] = useState([]);
  const [counter, setCounter] = useState(0);
  const [initialItem, setInitialItem] = useState({});
  const [additionFields, setAdditionFields] = useState();
  const [additionVals, setAdditionVals] = useState();
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const imageInput = useRef();
  const [formFields, setFormFields] = useState([]);
  const [images, setImages] = useState([]);
  const [types, setTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [inputImages, setInputImages] = useState([]);
  const [inputFiles, setInputFiles] = useState([]);
  const [docName, setDocName] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [delImg, setDelImg] = useState([]);
  const [delDoc, setDelDoc] = useState([]);
  // const [flag, setFlag] = useState(true);
  const [newLabel, setNewLabel] = useState("");
  const [typeObj, setTypeObj] = useState([]);
  //const [newFields, setNewFields] = useState({});
  const [dynamicFields, setDynamicFields] = useState([]);
  const [industryFilters, setIndustryFilters] = useState([]);



  const IndustryDropdown = ({ industryFilters, setIndustryFilters }) => {
    const dropdownRef = useRef(null);
    const industries = [
      "Aerospace",
      "Agriculture",
      "Computer",
      "Construction",
      "Education",
      "Electronics",
      "Energy",
      "Entertainment",
      "Food",
      "Health Care",
      "Hospitality",
      "Manufacturing",
      "Mining",
      "Music",
      "News Media",
      "Pharmaceutical",
      "Telecommunication",
      "Transport",
      "World Wide Web"
    ];




    const handleIndustryChange = (e, industry) => {
      e.stopPropagation();
      e.preventDefault();
      if (industryFilters.includes(industry)) {
        setIndustryFilters(prev => prev.filter(item => item !== industry));
      } else {
        setIndustryFilters(prev => [...prev, industry]);
      }
    };

    const selectAllIndustries = () => {
      if (industryFilters.length === industries.length) {
        setIndustryFilters([]);
      } else {
        setIndustryFilters(industries);
      }
    };

    return (
      <div className="row">
        <div className="col-md-3">
          <label>Target Industry <span>*</span></label>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <Dropdown ref={dropdownRef}>
              <Dropdown.Toggle
                variant="light" // White background
                style={{ fontSize: '0.8rem', color: 'black' }} // Smaller size and black text
              >
                Target Industry
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropDown_wrapper">
                <div className="p-3 d-flex flex-column" style={{ height: '18rem', overflowY: 'scroll', gap: '5px' }}>
                  <div className="d-flex" style={{ gap: '0 8px' }}>
                    <input type="checkbox" name="selectAll" style={{ backgroundColor: 'white' }} checked={industryFilters.length === industries.length} onChange={selectAllIndustries} onClick={(e) => e.stopPropagation()} />
                    <label htmlFor="selectAll">Select All</label>
                  </div>

                  {industries.map((industry) =>
                    <div key={industry} className="d-flex" style={{ gap: '0 10px' }}>
                      <input
                        type="checkbox"
                        name={industry}
                        value={industry}
                        onClick={(e) => handleIndustryChange(e, industry)}
                        checked={industryFilters.includes(industry)}
                        style={{ backgroundColor: 'white' }}
                      />
                      <label
                        style={{ fontSize: '12px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleIndustryChange(e, industry);
                        }}
                      >
                        {industry}
                      </label>
                    </div>
                  )}








                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  };

  console.log(typeObj)
  const handleLabelInput = (e) => {
    setNewLabel(e.target.value);
  };


  const addFormField = () => {
    setNewLabels([...newLabels, newLabel]);
    setDynamicFields((prevFields) => {
      const newField = {
        label: newLabel,
        name: `new-field${counter}`,
        placeholder: "Enter New Field",
        value: initialItem[newLabels[counter]],
        type: "text",
      };
      return [...prevFields, newField];
    });
    setCounter(counter + 1);
  };

  useEffect(() => {
    axios
      .get(`/get_item/${location.state.InventoryNumber}`)
      .then((res) => {
        console.log(res.data);
        setInitialItem(res.data.item[0]);
        setAdditionFields(res.data.item[0].extendedFieldName?.split(","));
        setIndustryFilters(res.data.item[0].associatedIndustries?.split(","));

        setAdditionVals(res.data.item[0].extendedFieldValues?.split(","));
        console.log(additionVals);

        const formattedDate = moment(
          new Date(res.data.item[0].itemExpiry)
        ).format("YYYY-MM-DD");
      })
      .catch((error) => {
        console.log(error);
      });

    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    if (Object.keys(initialItem).length > 0) {
      if (pcount === 0) {
        console.log("ANDARRRR", initialItem);
        setAdditionFields(initialItem.extendedFieldName?.split(","));
        console.log(additionFields);
        setAdditionVals(initialItem.extendedFieldValues?.split(","));
        axios.get("/get_documentTypes").then((response) => {
          console.log(response.data);
          const newArr = response.data.map((type) => type.documentType);
          setTypes(newArr);
        });
        const imageArr = [];
        let filesfromAPI = [];
        
        if (initialItem.itemImage)
        {
          imageArr.push(initialItem.itemImage);
        }
        
        additionFields?.forEach((field, index) => 
        {
          if (field === "document") {
            filesfromAPI.push(additionVals[index]);
          }
          if (field === "images") {
            imageArr.push(additionVals[index]);
          }
        });
        setImages(imageArr);
        setFiles(filesfromAPI);

        if (initialItem.extendedDoc?.includes(","))
        {
          setDocName(initialItem.extendedDoc?.split(","));
        }
        else
        {
          setDocName([initialItem.extendedDoc]);
        }

        setFileName(initialItem.extendedFileNames?.split(","));

        setPcount(pcount + 1);
      }
      const fileDocPairs = files.map((file, index) => ({ file: file, docname: docName[index] }));
      setTypeObj(fileDocPairs)
      console.log(additionFields);
      let formattedDate = ""; // Declare outside the if statement

      if (initialItem.itemExpiry) {
        formattedDate = moment(new Date(initialItem.itemExpiry)).format("YYYY-MM-DD");
      }

      console.log(files);
      const fixedFormFields = [
        {
          label: "Size",
          name: "size",
          placeholder: "Enter Size",
          type: "number",
          value: initialItem.itemSize,
          step: "any",
        },
        {
          label: "Physical State",
          name: "physical-state",
          placeholder: "Enter Physical State",
          value: initialItem.itemState,
          type: "text",
        },
        {
          label: "Grade Standard",
          name: "grade-standard",
          placeholder: "Enter Grade Standard",
          value: initialItem.itemGrade,
          type: "text",
        },
        {
          label: "Material Number",
          name: "material-number",
          placeholder: "Enter Material Number",
          value: initialItem.itemMaterialNumber,
          type: "text",
        },
        {
          label: "Expiry Date",
          name: "expiry-date",
          placeholder: "Enter Expiry Date",
          value: formattedDate,
          type: "date",
        },
        {
          label: "Quantity",
          name: "quantity",
          placeholder: "Enter Quantity",
          type: "number",
          value: initialItem.itemQuantity,
          step: "any",
        },
        {
          label: "Minimum Buying Quantity",
          name: "min-buy-quantity",
          placeholder: "Enter Minimum Buying Quantity",
          type: "number",
          value: initialItem.itemMinQuantity,
          step: "any"
        }];

      if (additionFields) {
        setFormFields([
          ...fixedFormFields,
          ...additionFields.reduce((acc, fieldName, index) => {
            if (fieldName !== "images" && fieldName !== "document") {
              acc.push({
                label: fieldName,
                name: fieldName,
                value: additionVals[index],
                type: "text",
              });
            }
            return acc;
          }, []),
        ]);
      }
      else {
        setFormFields(fixedFormFields);
      }

    }
  }, [initialItem]);

  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };

  const handleImageChange = (e) => {
    const imagefiles = Array.from(e.target.files);
    setInputImages((prevImages) => [...prevImages, ...imagefiles]);
    // setImages(files);
  };
  const handleInputChange = (event) => {
    let tempItem = { ...initialItem };
    let { name, value } = event.target;
    // if ((name === 'quantity' || name === 'min-buy-quantity') && value < 1) {
    //   value = 1;
    // }
    //let newFieldItem = { ...newFields };

    if (event.target.name === "name") tempItem.itemName = event.target.value;
    //tempItem["Name"] = event.target.value;
    else if (event.target.name === "price")
      tempItem.itemPrice = event.target.value;
    else if (event.target.name === "industry")
      tempItem.itemIndustry = event.target.value;
    else if (event.target.name === "category")
      tempItem.itemCategory = event.target.value;
    //tempItem["Category"] = event.target.value;
    //tempItem["BidChoice"] = event.target.value;
    else if (event.target.name === "packaging")
      //tempItem["Packaging"] = event.target.value;
      tempItem.itemPackaging = event.target.value;
    else if (event.target.name === "description")
      //tempItem["Description"] = event.target.value;
      tempItem.itemDesc = event.target.value;
    else if (event.target.name === "size")
      //tempItem["Size"] = event.target.value;
      tempItem.itemSize = event.target.value;
    else if (event.target.name === "physical-state")
      //tempItem["PhysicalState"] = event.target.value;
      tempItem.itemState = event.target.value;
    else if (event.target.name === "grade-standard")
      //tempItem["GradeStandard"] = event.target.value;
      tempItem.itemGrade = event.target.value;
    else if (event.target.name === "material-number")
      //tempItem["MaterialNumber"] = event.target.value;
      tempItem.itemMaterialNumber = event.target.value;
    else if (event.target.name === "expiry-date") {
      // const selectedDate = new Date(event.target.value);
      // setFormattedDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
      tempItem.itemExpiry = event.target.value;
    }
    //tempItem["ExpiryDate"] = event.target.value;
    else if (event.target.name === "quantity")
      //tempItem["Quantity"] = event.target.value;
      tempItem.itemQuantity = event.target.value;
    else if (event.target.name === "min-buy-quantity")
      //tempItem["Quantity"] = event.target.value;
      tempItem.itemMinQuantity = event.target.value;
    else if (event.target.name === "uom")
      //tempItem["UoM"] = event.target.value;
      tempItem["itemUom"] = event.target.value;
    else {
      console.log("INSIDEEE");
      for (let x = 0; x < counter; x++) {
        if (event.target.name === `new-field${x}`) {
          tempItem[newLabels[x]] = event.target.value;
        }
      }
      console.log(additionFields, additionVals);
      for (let x = 0; x < additionFields.length; x++) {
        if (additionFields[x] != "images" && additionFields[x] != "document") {
          console.log(additionFields[x]);
          if (event.target.name === additionFields[x]) {
            tempItem[additionFields[x]] = event.target.value;
            additionVals[x] = event.target.value;
          }
        }
      }
    }

    console.log(initialItem);

    tempItem["InventoryNumber"] = 321;
    tempItem["OnHandInventory"] = "164 Products";
    tempItem["Shipping"] = "Thursday, 18 Aug, 2022";
    tempItem["Status"] = "Unpublished";

    setInitialItem(tempItem);
    //setNewFields(newFieldItem);
    //console.log("VERY NEW", newFieldItem);
  };
  const handleFileName = (index, e) => {
    setDocName((prevDocNames) => {
      const newDocNames = [...prevDocNames];
      newDocNames[index] = e.target.value;
      return newDocNames;
    });
    console.log(docName);
    setTypeObj((prevTypes) => {
      return prevTypes.map((file, i) => {
        if (i === index) {
          // Create a new object with the same "file" value but with the updated "docname" value
          return { file: file.file, docname: e.target.value };
        } else {
          return file; // Return the original object for all other indices
        }
      });
    });
    console.log(typeObj)
  };

  const handleCancel = () => {
    navigate("/admin/item-listing/inventory-list");
  };
  const handleImageDelete = (index) => {
    const newImages = [...images];
    const deletedImage = newImages.splice(index, 1)[0];
    setImages(newImages);
    setDelImg((prevDelImg) => [...prevDelImg, deletedImage]);
    console.log(delImg);
  };

  const handleImageDelete2 = (index) => {
    const newImages = [...inputImages];
    newImages.splice(index, 1);
    setInputImages(newImages);
  };
  const handleFileChange = (e) => {
    console.log("INSIDE FUNCTION");
    const docfiles = Array.from(e.target.files);
    setInputFiles((prevFiles) => [...prevFiles, ...docfiles]);

    // Extract filenames from uploaded files
    const filenames = docfiles.map((file) => file.name);
    console.log(filenames);

    // Append filenames to the fileName state
    if (fileName) {
      setFileName((prevNames) => [...prevNames, ...filenames]);
    }
    else {
      setFileName(filenames);
    }

    console.log(fileName);
  };
  const handleDelete = (index) => {
    if (inputFiles) {
      setInputFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
    }

    setFileName((prevNames) => prevNames.filter((name, i) => i !== index));
    setDocName((prevDocNames) =>
      prevDocNames.filter((docName, i) => i !== index)
    );
    setTypeObj((prevTypeObj) => prevTypeObj.filter((obj, i) => i !== index));
    if (files[index]) {
      setDelDoc((prevDelDoc) => {
        if (files[index]) {
          const deletedDoc = files[index];
          return [...prevDelDoc, deletedDoc];
        }
      });
    }
  };

  const values = [
    "Raw Materials",
    "Packaging Materials",
    "Semi Finished Products",
    "Finished Products",
    "Machines & Tools",
    "Machine Spare Parts",
    "Scrap Material",
  ];
  const extractUUIDAndExtension = (url) => {
    if (typeof url !== 'string') {
      console.error("The provided URL is not a string:", url);
      return null;
    }

    const segments = url.split("/");
    const fullFilename = segments[segments.length - 1].split("?")[0];
    return fullFilename;
  };

  const handleDone = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("itemName", initialItem.itemName);
    let count = 0;
    formFields.forEach((field) => {
      if (count > 6) {
        console.log("FIELDS", field);
        if (initialItem[field.label] !== undefined) {
          formData.append(field.label, initialItem[field.label]);
        }

      }
      count = count + 1;
    });
    formData.append("itemIndustry", initialItem.itemIndustry);
    formData.append("itemCategory", initialItem.itemCategory);
    // formData.append('itemBid', initialItem.itemBidChoice);
    formData.append("itemPackaging", initialItem.itemPackaging);
    formData.append("itemDesc", initialItem.itemDesc);
    formData.append("itemSize", initialItem.itemSize);
    formData.append("itemState", initialItem.itemState);
    formData.append("itemGrade", initialItem.itemGrade);
    formData.append("itemMaterialNumber", initialItem.itemMaterialNumber);
    formData.append("itemExpiry", initialItem.itemExpiry);
    formData.append("itemQuantity", initialItem.itemQuantity);
    formData.append("itemUom", initialItem.itemUom);
    formData.append("itemPrice", initialItem.itemPrice);
    inputImages.forEach((image, index) => {
      // const processedImage = extractUUIDAndExtension(image);
      formData.append(`image_${index}`, image);
    });

    inputFiles.forEach((doc, index) => {
      // const processedDoc = extractUUIDAndExtension(doc);
      formData.append(`doc_${index}`, doc);
      if (docName?.length > 0) {
        let j = docName.length - index - 1;
        // const processedDocName = extractUUIDAndExtension(docName[j]);
        formData.append(`docName_${index}`, docName[j]);
      }
      else {
        let j = fileName.length - index - 1;
        // const processedDocName = extractUUIDAndExtension(docName[j]);
        formData.append(`docName_${index}`, fileName[j]);
      }
    });
    let x = 0;
    dynamicFields.forEach((field, index) => {
      if (initialItem[field.label] !== undefined) {
        formData.append(field.label, initialItem[field.label]);
      }
    });
    formData.append("typeObj", JSON.stringify(typeObj));
    delDoc.forEach((name, index) => {
      const processedName = extractUUIDAndExtension(name);
      formData.append(`delDoc${index}`, processedName);
    });
    formData.append("Industries", JSON.stringify(industryFilters));

    delImg.forEach((name, index) => {
      const processedName = extractUUIDAndExtension(name);
      formData.append(`delImg${index}`, processedName);
    });
    //formData.append('itemFile', file);
    console.log(inputImages);
    // const objToSend = {
    //   itemName: initialItem.itemName,
    //   itemIndustry: initialItem.itemIndustry,
    //   itemCategory: initialItem.itemCategory,
    //   itemPackaging: initialItem.itemPackaging,
    //   itemDesc: initialItem.itemDesc,
    //   itemSize: initialItem.itemSize,
    //   itemState: initialItem.itemState,
    //   itemGrade: initialItem.itemGrade,
    //   itemMaterialNumber: initialItem.itemMaterialNumber,
    //   itemExpiry: initialItem.itemExpiry,
    //   itemQuantity: initialItem.itemQuantity,
    //   itemUom: initialItem.itemUom,
    //   itemPrice: initialItem.itemPrice,
    // };
    axios
      .put(
        `/updateitem/${location.state.InventoryNumber}`,
        formData
      )
      .then((response) => {
        console.log(response.data);
        props.setMessage("Item Updated Successfully.");
        navigate("/admin/item-listing/inventory-list", { state: item });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="create_items">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}
          {initialItem && (
            <div className="admin_panel">
              <div className="d-lg-flex">
                <div
                  className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                    }`}
                >
                  <Sidebar
                    collapsed={collapseSidebar}
                    collapsedDropdown={collapseDropdown}
                  />
                </div>
                <div
                  className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                    }`}
                >
                  {!matches && (
                    <Header
                      getCollapseSidebar={getCollapseSidebar}
                      getCollapseDropdown={getCollapseDropdown}
                      logOut={props.logOut}
                    />
                  )}

                  <div
                    className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                      }`}
                  >
                    <div className="content_panel">
                      <h3 className="pageName_heading">Update Item</h3>
                      <form onSubmit={handleDone}>
                        <div className="createItem d-md-flex">
                          <div
                            className="upload_image"
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              multiple
                              required={!(images && images.length > 0) && !(inputImages && inputImages.length > 0)}


                            />
                            {images &&
                              images.length > 0 &&
                              images.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    flex: "0 0 25%",
                                    padding: "10px",
                                  }}
                                >
                                  <img loading="lazy"
                                    src={`${image}`}
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      backgroundColor: "#ffffff",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="12"
                                      height="12"
                                      fill="#000000"
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z" />
                                    </svg>
                                  </span>
                                </div>
                              ))}

                            {inputImages &&
                              inputImages.length > 0 &&
                              inputImages.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    flex: "0 0 25%",
                                    padding: "10px",
                                  }}
                                >
                                  <img loading="lazy"
                                    src={URL.createObjectURL(image)}
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      backgroundColor: "#ffffff",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleImageDelete2(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="12"
                                      height="12"
                                      fill="#000000"
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z" />
                                    </svg>
                                  </span>
                                </div>
                              ))}
                          </div>
                          <div className="buttons_group">
                            <button
                              type="button"
                              className="cancel"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button type="submit" className="done">
                              <AiOutlineCheckCircle />
                              Update
                            </button>
                          </div>
                        </div>
                        <div className="secondaryContainer">
                          <span>Core Information</span>
                          <br />
                          <span>
                            <small>
                              <RiErrorWarningLine
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              Specify the primary description for your item.
                            </small>
                          </span>
                        </div>

                        <div className="createItemForm">
                          <div className="row" style={{ marginTop: "0" }}>
                            <div className="col-md-3">
                              <label>
                                Item Name <span>*</span>
                              </label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  value={initialItem.itemName}
                                  className="form-control"
                                  placeholder="Enter Item Name"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col-md-3">
                              <label>
                                Target Industry <span>*</span>
                              </label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <select
                                  name="industry"
                                  className="form-select"
                                  defaultValue=""
                                  value={initialItem.itemIndustry}
                                  onChange={handleInputChange}
                                  required
                                >
                                  <option value="" hidden>
                                    Select Target Industry
                                  </option>
                                  <option value="aerospace">Aerospace</option>
                                  <option value="agriculture">
                                    Agriculture
                                  </option>
                                  <option value="computer">Computer</option>
                                  <option value="construction">
                                    Construction
                                  </option>
                                  <option value="education">Education</option>
                                  <option value="electronics">
                                    Electronics
                                  </option>
                                  <option value="energy">Energy</option>
                                  <option value="entertainment">
                                    Entertainment
                                  </option>
                                  <option value="food">Food</option>
                                  <option value="healthcare">
                                    Health Care
                                  </option>
                                  <option value="hospitality">
                                    Hospitality
                                  </option>
                                  <option value="manufacturing">
                                    Manufacturing
                                  </option>
                                  <option value="mining">Mining</option>
                                  <option value="music">Music</option>
                                  <option value="news-media">News Media</option>
                                  <option value="pharmaceutical">
                                    Pharmaceutical
                                  </option>
                                  <option value="telecommunication">
                                    Telecommunication
                                  </option>
                                  <option value="transport">Transport</option>
                                  <option value="world-wide-web">
                                    World Wide Web
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div> */}
                          <IndustryDropdown industryFilters={industryFilters} setIndustryFilters={setIndustryFilters} />
                          <div className="row">
                            <div className="col-md-3">
                              <label>
                                Category <span>*</span>
                              </label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <select
                                  name="category"
                                  value={initialItem.itemCategory}
                                  className="form-select"
                                  onChange={handleInputChange}
                                  required
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <option value={value} key={index}>
                                        {value}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-1 col-md-2 tooltip_container">
                              <Tooltip content="Enter the best known keyword which can be used to describe your item.">
                                <BsInfoCircle />
                              </Tooltip>
                            </div>
                          </div>
                          {/* <div className="row">
                          <div className="col-md-3">
                            <label>Bid Choice <span>*</span></label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <select name="bid-choice" className="form-select" defaultValue="" value = {initialItem[4]}onChange={handleInputChange} required>
                                <option value="" hidden>Select Bid Choice</option>
                                <option value="Fixed Price">Fixed Price</option>
                                <option value="Open Bidding">Open Bidding</option>
                                <option value="Close Bidding">Close Bidding</option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                          <div className="row">
                            <div className="col-md-3">
                              <label>Packaging <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="packaging"
                                  className="form-control"
                                  placeholder="Enter Packaging"
                                  value={initialItem.itemPackaging}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-1 col-md-2 tooltip_container">
                              <Tooltip content="How is your item packed with respect to its state e.g., drums for liquid etc.">
                                <BsInfoCircle />
                              </Tooltip>
                            </div>
                          </div>
                          {/* <div className="row">
                          <div className="col-md-3">
                            <label>Documents</label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="file"
                                name="documents"
                                className="form-control"
                                placeholder="Choose file"
                                onChange={handleFileChange}
                                multiple
                              />
                              {files &&
                                files.length > 0 &&
                                files.map((file, index) => (
                                  <div
                                    key={index}
                                    style={{ flex: "0 0 25%", padding: "10px" }}
                                  >
                                    <span>{file.name}</span>
                                    <select
                                      className="form-select"
                                      type="text"
                                      placeholder="Enter file name"
                                      onChange={(e) => handleFileName(index, e)}
                                    >
                                      {types.map((option, optionIndex) => (
                                        <option
                                          key={optionIndex}
                                          value={option}
                                        >
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="How is your item packed with respect to its state e.g., drums for liquid etc.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div> */}
                          <div className="row">
                            <div className="col-md-3">
                              <label>Documents  <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="file"
                                  name="documents"
                                  className="form-control"
                                  accept=".pdf"
                                  placeholder="Choose file"
                                  onChange={handleFileChange}
                                  multiple
                                  required={!(fileName && fileName.length > 0)}
                                />

                                {fileName &&
                                  fileName.length > 0 &&
                                  fileName.map((file, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        flex: "0 0 25%",
                                        padding: "10px",
                                      }}
                                    >
                                      <span>
                                        {fileName[index]}{" "}
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="mr-5"
                                          onClick={() => handleDelete(index)}
                                        />
                                      </span>

                                      <select
                                        className="form-select"
                                        type="text"
                                        placeholder="Enter file name"
                                        value={docName?.length > 0 ? docName[index] : fileName[index]}
                                        onChange={(e) =>
                                          handleFileName(index, e)
                                        }
                                      >
                                        {types.map((option, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={option}
                                          >
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="col-1 col-md-2 tooltip_container">
                              <Tooltip content="Please upload any relevant documents or files related to this item. Only enter PDFs.">
                                <BsInfoCircle />
                              </Tooltip>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>Price <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="price"
                                  className="form-control"
                                  placeholder="Enter Price"
                                  value={initialItem.itemPrice}
                                  onChange={handleInputChange}
                                  step="0.01"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>Unit of Measurement <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="uom"
                                  className="form-control"
                                  placeholder="Enter UoM"
                                  value={initialItem.itemUom}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>Description <span>*</span></label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <textarea
                                  rows="4"
                                  name="description"
                                  className="form-control"
                                  value={initialItem.itemDesc}
                                  style={{ resize: "none" }}
                                  placeholder="Enter Description"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="secondaryContainer">
                          <span>Extended Information</span>
                          <br />
                          <span>
                            <small>
                              <RiErrorWarningLine
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              Specify other additional details for your item.
                            </small>
                          </span>
                        </div>

                        <div className="createItemForm">
                          {initialItem &&
                            formFields.map((field, index) => (
                              <div className="row" key={index}>
                                <div className="col-md-3">
                                  <label>
                                    {field.label} {field.name === 'quantity' || field.name === 'min-buy-quantity' ? <span>*</span> : null}
                                  </label>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-group">
                                    {field.type === "date" ? (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        //className="form-control"
                                        placeholder={field.placeholder}
                                        value={field.value}
                                        onChange={handleInputChange}
                                        style={{
                                          border: "1px solid #ced4da",
                                          borderRadius: "0.25rem",
                                          padding: "0.375rem 0.75rem",
                                          backgroundColor: "white"
                                        }}
                                      // required
                                      />
                                    ) : (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        value={field.value}
                                        className="form-control"
                                        placeholder={field.placeholder}
                                        onChange={handleInputChange}
                                        required={field.name === 'quantity' || field.name === 'min-buy-quantity'}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* <div className="row">
                            <div className="col-md-3">
                              <label>Enter New Label:</label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="new-label"
                                  className="form-control"
                                  placeholder="Enter Label"
                                  value={newLabel}
                                  onChange={handleLabelInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-5 offset-md-3">
                              <Button
                                className="add-field-button"
                                onClick={addFormField}
                                disabled={newLabel === ""}
                              >
                                Add Label
                              </Button>
                            </div>
                          </div> */}
                          {/* </div> */}
                          {/* <div className="secondaryContainer">
                          <span>Additional Information</span>
                          <br />
                          <span>
                            <small>
                              <RiErrorWarningLine
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              Specify other additional details for your item.
                            </small>
                          </span>
                        </div> */}
                          {/* <div className="createItemForm"> */}
                          {initialItem &&
                            dynamicFields.map((field, index) => (
                              <div className="row" key={index}>
                                <div className="col-md-3">
                                  <label>
                                    {field.label} {field.name === 'quantity' || field.name === 'min-buy-quantity' || field.type === 'date' ? <span>*</span> : null}
                                  </label>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-group">
                                    {field.type === "date" ? (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        //className="form-control"
                                        placeholder={field.placeholder}
                                        value={field.value}
                                        onChange={handleInputChange}
                                        style={{
                                          border: "1px solid #ced4da",
                                          borderRadius: "0.25rem",
                                          padding: "0.375rem 0.75rem",
                                          backgroundColor: "#ffffff",
                                          color: "#000000",
                                        }}
                                        min={new Date().toISOString().split('T')[0]}
                                        required
                                      />
                                    ) : (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        value={field.value}
                                        className="form-control"
                                        placeholder={field.placeholder}
                                        onChange={handleInputChange}
                                        required={field.name === 'quantity' || field.name === 'min-buy-quantity'}
                                        min={(field.name === 'quantity' || field.name === 'min-buy-quantity') ? 1 : undefined}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="row">
                            <div className="col-md-3">
                              <label>Enter New Label:</label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="new-label"
                                  className="form-control"
                                  placeholder="Enter Label"
                                  value={newLabel}
                                  onChange={handleLabelInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-5 offset-md-3">
                              <Button
                                className="add-field-button"
                                onClick={addFormField}
                                disabled={newLabel === ""}
                              >
                                Add Label
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UpdateItem;
