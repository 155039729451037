import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { ImSearch } from "react-icons/im";
import { BsFunnel } from "react-icons/bs";
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import Stack from '@mui/material/Stack';
import { GridEvents, useGridApiRef } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button, ToastContainer, Row, Col } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import TxModal from "./TxModal";
import "../../css/style.css";
import "../../css/inventory.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { toast } from "react-toastify";
import CustomDownloader from "./CustomDownloader";

const LotList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const id = currentUser.id;
  const [columns, setColumns] = useState([]);
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [csvHeaders, setCSVHeaders] = useState([]);
  const [csvData, setCSVData] = useState([]);
  //   const [selectedRows, setSelectedRows] = useState([]);
  //   const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showTxModal, setShowTxModal] = useState(false);
  const [txModalType, setTxModalType] = useState();
  const [txTitle, setTxTitle] = useState("");
  const [txMsg, setTxMsg] = useState("");
  const [txData, setTxData] = useState();
  const [searchColumn, setSearchColumn] = useState(null);

  const [delId, setDelId] = useState();
  const [selected, setSelected] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  let navigate = useNavigate();
  const { state } = useLocation();
  const styles = {
    container: {
      height: "500px", // set the height of the container
    },
  };
  const [editRowsModel, setEditRowsModel] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [expiryDate, setExpiryDate] = useState(new Date());

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditRowModelChange = (newModel) => {
    setEditRowsModel(newModel);
  };
  const addSpacesToColumnName = (str) => str.replace(/([A-Z])/g, ' $1').trim();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/lots/${id}`)
      .then((res) => {
        let data = res.data.lots;
        const expectedOrder = [
          'LotId', 'Name', 'Description', 'BidChoice', 
          'Deadline', 'Price', 'Commission', 'Published', 'TotalPrice'
        ];
  
        // Create dynamic columns based on the expected order
        const tempColumns = expectedOrder.map((columnName) => {
          let column = {
            field: columnName,
            headerName: addSpacesToColumnName(columnName),
            flex: 1,
          };
  
          if (typeof data[0][columnName] === 'number') {
            column.type = 'number';
            column.align = 'right';
          } else if (typeof data[0][columnName] === 'string') {
            column.align = 'left';
          } else {
            column.align = 'center';
          }
  
          if (columnName === 'Price' || columnName === 'TotalPrice') {
            column.renderCell = (params) => `${params.value} ${currentUser.currency}`;
          }
  
          return column;
        });
  
        // Add the "Actions" column
        tempColumns.push({
          field: 'Actions',
          headerName: 'Actions',
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          minWidth:200,
          flex: 1,
          align: 'center',
          renderCell: (params) => (
            <div>
              <FontAwesomeIcon
                icon={faTrash}
                className="mr-5"
                onClick={() => {
                  handleDelete(params.row.LotId, params.row.Name);
                }}
              />
              <FontAwesomeIcon
                icon={faEdit}
                className="mr-5"
                onClick={() => {
                  navigate('/admin/item-listing/update-lot', {
                    state: params.row,
                  });
                }}
              />
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => {
                  navigate('/admin/item-listing/view-lot', {
                    state: params.row,
                  });
                }}
              />
            </div>
          ),
        });
  
        setIsLoading(false);
        setColumns(tempColumns);
        setRows(data);
        setFilteredRows(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  
    window
      .matchMedia('(max-width: 1024px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);
  

  const handleRowSelection = (selectedRows) => {
    setSelected(selectedRows);
    console.log(selectedRows);
  };

  const handleDelete = async (id,name) => {
    setDelId(id);
    setTxModalType(1);
    setTxTitle(`Deletion Confirmation`);
    setTxMsg(`Are you sure you want to delete ${name}?`);
    //setTxData(tableMeta.rowData[0]);
    setShowTxModal(true);
  };
  const handleDeleteSelected = async (id,name) => {
    setDelId(null);
    setTxModalType(1);
    setTxTitle(`Deletion Confirmation`);
    setTxMsg(`Are you sure you want to delete selected lots?`);
    //setTxData(tableMeta.rowData[0]);
    setShowTxModal(true);
  };
  const deleteLot = async (LotId) => {
    return axios.delete(`/delete_lot/${LotId}`);
};

const deleteSelectedLots = async () => {
  setIsLoading(true);
  setShowTxModal(false);

  // Map over selected LotIds and create an array of delete promises
  const deletePromises = selected.map((LotId) => deleteLot(LotId));

  try {
      // Attempt to delete all selected lots concurrently
      await Promise.all(deletePromises);

      // Update rows to only include lots that were NOT selected for deletion
      const updatedRows = rows.filter(
          (row) => !selected.includes(row.LotId)
      );

      setRows(updatedRows);
      setFilteredRows(updatedRows); // Update the state with the new rows
      setSelected([]);  // Clear the selected lots after deletion

  } catch (error) {
      console.log(error); // Log any errors encountered during deletion
  }

  setIsLoading(false); // Set loading state to false after deletion
  setShowTxModal(false); // Hide the confirmation modal
};


  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };

  const handleDropdownClick = (event, search) => {
    event.preventDefault();
    setSearchColumn(search);
  };
  const reveal = async () => {
        setIsLoading(true)
    axios
      .delete(`/delete_lot/${delId}`)
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          console.log("IN DEL");
          const updatedRows = rows.filter(
            (row) => row.LotId !== delId
          );
          setIsLoading(false)
          setRows(updatedRows);
          setFilteredRows(updatedRows);
          setSelected([]);
        }
        setShowTxModal(false);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    let userInput = event.target.value;
    userInput = userInput.trim();

    if (userInput === "") {
      setFilteredRows(rows);
    } else {
      if (searchColumn) {
        if (searchColumn === "Name") {
          const result = rows.filter((search) =>
            search?.Name?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
          setFilteredRows(result);
        } else if (searchColumn === "Description") {
          const result = rows.filter((search) =>
            search?.Description?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
          setFilteredRows(result);
        } else if (searchColumn === "Deadline") {
              const result = rows.filter((search) => {
              // Parse the Deadline string into a moment object with the specified format
              const deadlineDate = moment(search.Deadline, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
              
              // Format the deadline to match the user input format (MM/DD/YYYY)
              const formattedDeadline = deadlineDate.format("MM/DD/YYYY");
              
              // Compare with the user input
              return moment(formattedDeadline, "MM/DD/YYYY").isSame(userInput);
          });
      
      
          setFilteredRows(result);
        }
      } else {
        const result = rows.filter(
          (search) =>
            search?.Name?.toLowerCase()?.includes(userInput?.toLowerCase()) ||
            search?.Description?.toLowerCase()?.includes(userInput?.toLowerCase())
        );
        setFilteredRows(result);
      }
    }
  };

  const handleCSVClick = () => {
    let headers = [];

    for (var i = 0; i < columns.length; i++) {
      headers.push({ label: columns[i].name, key: Object.keys(rows[0])[i] });
    }

    setCSVHeaders(headers);
    setCSVData(filteredRows);
  };


  const handleCreateLot = () => {
    navigate("/admin/item-listing/create-lot");
  };

  const handlePublishItem = () => {
    if (selected.length === 0) {
      setShowModal(true);
    } else {
      axios
        .post("/publish_lot", {
          lotIds: selected,
          sellerId: currentUser.id
        })
        .then((response) => {
          console.log(response.data);
          if(response.status === 202){
            toast.error(response.data.message);
            setIsLoading(false);
          }
          else{
            navigate("/admin/item-listing/published-products");
          }
         
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="inventory_list_page">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${
                  collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>

              <div
                className={`inventory_list_content ${
                  collapseSidebar ? "open_content" : "pinched_content"
                }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}

                {props.message && (
                  <ToastContainer className="messageToast">
                    <Toast
                      onClose={() => {
                        props.setMessage(null);
                      }}
                      delay={3000}
                      autohide
                    >
                      <Toast.Header>
                        <strong className="me-auto">Message</strong>
                        <small>2 secs ago</small>
                      </Toast.Header>
                      <Toast.Body>{props.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                )}

                <div
                  className={`w-100 content_panel ${
                    collapseSidebar ? "slide-in" : "slide-out"
                  }`}
                >
                  <div style={{ height: "100vh" }}>
                    <h3 className="pageName_heading">
                    {localStorage.getItem('logoUrl') && (
  <img
    src={localStorage.getItem('logoUrl')}
    alt="Company Logo"
    style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }}
  />
)}
Lot List</h3>

                    <div className="listing row">
                      <div className="col-12 col-md-6 h-[45px] position-relative">
                       
                        <div className="admin_panel_searchbar">
                          <input
                            type={`${
                              searchColumn === "Deadline" ? "date" : "text"
                            }`}
                            placeholder={`Search ${
                              searchColumn ? searchColumn : "Inventory"
                            }`}
                            className="input py-[1.5em] px-[3em]"
                            onChange={(event) => handleInputChange(event)}
                          />
                          <button
                            className="filter dropdown-toggle"
                            type="button"
                            id="filterButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <BsFunnel
                              style={{ color: "black", fontSize: "18px" }}
                            />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="filterButton"
                            style={{ zIndex: "1", fontSize: "14px" }}
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                href="#/"
                                onClick={(event) =>
                                  handleDropdownClick(event, "Name")
                                }
                              >
                                Filter By Name
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#/"
                                onClick={(event) =>
                                  handleDropdownClick(event, "Description")
                                }
                              >
                                Filter By Description
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#/"
                                onClick={(event) =>
                                  handleDropdownClick(event, "Deadline")
                                }
                              >
                                Filter By Deadline
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* <div className = "btn-group">
                    <button className = "actions dropdown-toggle" type = "button" id = "actionbutton" data-bs-toggle = "dropdown" aria-expanded = "false">
                      ACTIONS
                    </button> 
                    <ul className = "dropdown-menu" aria-labelledby = "actionbutton">
                      <li><a className = "dropdown-item" href = "/#">Filter By Name</a></li>
                    </ul>
                    
                    <button className = "view dropdown-toggle" type = "button" id = "viewButton" data-bs-toggle = "dropdown" aria-expanded = "false">
                      Select View
                    </button>
                    <ul className = "dropdown-menu" aria-labelledby = "viewButton">
                      <li><a className = "dropdown-item" href = "/#">Filter By Name</a></li>
                    </ul>
                  </div> */}
                      </div>
                      <div className="col-12 col-md-6 ">
                        <div className="admin_couple_buttons">
                          <div className="text-center">
                            <button
                              type="button"
                              className="createItemButton"
                              onClick={handleCreateLot}
                            >
                              Create Lot
                            </button>
                          </div>

                          {/* {filteredRows && filteredRows.length > 0 && (
                            <div className="text-center">
                              <CSVLink
                                className="export"
                                data={csvData}
                                headers={csvHeaders}
                                asyncOnClick={true}
                                filename={"Inventory List.csv"}
                                onClick={handleCSVClick}
                                style={{ color: "#000000de" }}
                              >
                                EXPORT TO CSV
                              </CSVLink>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>

                    <div style={styles.container} className="customCard">
                      <GridContextProvider>
                        <DataGrid
                          rows={filteredRows}
                          columns={columns}
                          getRowId={(row) => row.LotId}
                          onSelectionModelChange={handleRowSelection}
                          selectionModel={selected}
                          loading={isLoading}
                          checkboxSelection={!isLoading}
                          rowHeight={40}
                          // rowsPerPageOptions={[5]}
                          pagination
                          density="compact"
                          disableExtendRowFullWidth={true}
                          disableSelectionOnClick={false}
                          components={{
                            NoRowsOverlay: () => (
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                There are no records to display
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                There are no records to display
                              </Stack>
                            )
                          }}
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                          }
                        />
                      </GridContextProvider>
                    </div>
                    {filteredRows && filteredRows.length > 0 && (
                
                <CustomDownloader
                    rows={filteredRows}
                    title={'Lot List'}
                />
                
        )}

                    {filteredRows && filteredRows.length > 0 && (
                      <button
                        type="button"
                        className="publish ml-5"
                        onClick={handlePublishItem}
                      >
                        Publish Lot(s)
                      </button>
                    )}
                    {filteredRows && filteredRows.length > 0 && (
                      <button
                        type="button"
                        className="publish"
                        onClick={handleDeleteSelected}
                      >
                        Delete Lot(s)
                      </button>
                      
                    )}
                    <br />

                    <Modal centered show={showModal} onHide={handleCloseModal}>
                      <Modal.Body>
                        <h1 className="text-slate-600 text-[18px] text-center p-[1em]">
                          Please select one or more items from the table before
                          publishing.
                        </h1>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="warning"
                          onClick={handleCloseModal}
                          style={{
                            margin: "auto",
                            width: "100px",
                            color: "white",
                          }}
                        >
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <TxModal
        title={txTitle}
        msg={txMsg}
        show={showTxModal}
        setShow={setShowTxModal}
        confirmTx={delId ? reveal : deleteSelectedLots}
        type={txModalType}
        login={false}
      />
      {/* <Modal show={show} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Enter Expiry Date</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <DatePicker selected={expiryDate} onChange={(date) => setExpiryDate(date)} />
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button variant="primary" onClick={submitExpiryDate}>
      Save Changes
    </Button>
  </Modal.Footer>
</Modal> */}
    </div>
  );
};

export default LotList;
