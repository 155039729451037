import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Logo from '../../images/Logo.png';
import Notifications from 'react-notifications-menu';
import teamImage from '../../images/MicrosoftTeams-image.png';
import '../../css/style.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../../css/sidePanel.css';
import SidePanel from './SidePanel';
import axios from "axios";
import moment from "moment";

const Header = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [notification, setNotification] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  const overlayHandler = () => {
    setOverlay(!overlay);
  }

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`/get_notifications?userId=${currentUser.id}`);
        const data = response.data.map(notification => ({
          image: "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png",
          message: notification.description,
          detailPage: notification.url,
          receivedTime: moment(notification.createdOn).fromNow()
        }));
        setNotification(data);
      } catch (error) {
        console.error("Error fetching notifications", error);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <Navbar className="navbar main_navbar navbar-dark text-white header" expand="lg">
      {overlay && <div className='overlay'></div>}
      <Container fluid className='p-0'>
        {matches &&
          (currentUser ?
            (
              <div className="dropdown dropdown-end text-gray-600 header-padding avatar_section">
                <label tabIndex="0" className="btn-header btn btn-ghost btn-circle avatar">
                  <img loading="lazy"  className='user_avatar' src="https://placeimg.com/80/80/people" alt="" />
                </label>
                <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 bg-slate-50 page-list">
                  {props?.userRole?.access === "admin" ? (
                    <li><a href="/admin/dashboard">Dashboard</a></li>
                  ) : (
                    <>
                      {[2, 3, 4].includes(currentUser.role) && (
                        <li><a href="/admin/item-listing/inventory-list">Portal</a></li>
                      )}
                      {[1].includes(currentUser.role) && (
                        <li><a href="/admin/sales-requests">Portal</a></li>
                      )}
                      <li><a href="/cart">Cart</a></li>
                      <li><a href="/negotiate-cart">Wishlist</a></li>
                      <li><a href="/admin/profile/edit-profile">Edit Profile</a></li>

                    </>

                  )}
                  <li><a href="#/" onClick={(event) => { event.preventDefault(); props.logOut(); }}>Logout</a></li>
                </ul>
              </div>

            ) : (
              <div className="navbar-center lg:flex text-[15px]" style={{width:'25.33%'}}>
                <ul className="menu menu-horizontal p-0 space-x-4 m-0">
                  <li><a href="/admin/login" className="p-0 font-bold text-white text-[10px]">Login / Sign Up</a></li>
                </ul>
              </div>
            )
          )
        }

        <Navbar.Brand href="#" style={{ marginRight: '0', width:'25.33%'}}>
          <a href="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img loading="lazy"  src={Logo} alt="Inv-X" className="border-logo" style={{ borderRadius: '40px' }} />
          </a>
        </Navbar.Brand>

        {matches && <SidePanel overlayHandler={overlayHandler} />}

        {!matches && (
          <Navbar.Collapse id="navbarScroll">
            <ul className="menu menu-horizontal p-0 space-x-4 m-0">
              <li><a href="/">Home</a></li>
              <li><a href="/faqs">FAQs</a></li>
              {
                !currentUser && (
<li><a href="/request-demo">Request a Demo</a></li>
                )
              }
              
              <li><a href="/pricing">Pricing</a></li>
              {/* <li><a href="#/" onClick={(event) => { event.preventDefault() }}>Knowledge Hub</a></li> */}
              <li tabIndex="0" className="ml-[60px]">
                <a href="#/" onClick={(event) => { event.preventDefault() }}>
                  Categories
                  <svg className="fill-current text-black rounded-lg bg-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                </a>
                <ul className="p-2 bg-slate-50 text-black page-list more-links" style={{ zIndex: '6' }}>
                  <li><a href="/search-product/Raw%20Materials">Raw Materials</a></li>
                  <li><a href="/search-product/Packaging%20Materials">Packaging Materials</a></li>
                  <li><a href="/search-product/Semi%20Finished%20Products">Semi Finished Products</a></li>
                  <li><a href="/search-product/Finished%20Products">Finished Products</a></li>
                  <li><a href="/search-product/Machines%20&amp;%20Tools">Machines &amp; Tools</a></li>
                  <li><a href="/search-product/Machine%20Spare%20Parts">Machine Spare Parts</a></li>
                  <li><a href="/search-product/Scrap%20Material">Scrap Material</a></li>
                </ul>
              </li>
            </ul>
            {!currentUser ? (
              <>
                <div className="navbar-center hidden lg:flex text-[15px]">
                  <ul className="menu menu-horizontal p-0 space-x-4 m-0">
                    <li><a href="/admin/login" className="font-bold text-white">Login / Sign Up</a></li>
                  </ul>
                </div>
              </>) : (
              <>
                <div className="dropdown dropdown-end text-gray-600 header-padding avatar_section">
                  <span className="mr-2 text-center text-white text-[15px]">
                    {currentUser.username}
                  </span>
                  <label tabIndex="0" className="btn-header btn btn-ghost btn-circle avatar">
                    <div className="rounded-full">
                      <img loading="lazy"  src={teamImage} alt="" />
                    </div>
                  </label>
                  <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 bg-slate-50 page-list">
                    {props?.userRole?.access === "admin" ? (
                      <li><a href="/admin/dashboard">Dashboard</a></li>
                    ) : (
                      <>
                        {[2, 4].includes(currentUser.role) && (
                          <li><a href="/admin/item-listing/inventory-list">Portal</a></li>
                        )}
                        {[3].includes(currentUser.role) && (
                          <li><a href="/admin/admin-section/users">Portal</a></li>
                          
                        )}
                        {[1].includes(currentUser.role) && (
                          <li><a href="/admin/sales-requests">Portal</a></li>
                        )}
                        <li><a href="/cart">Cart</a></li>
                        <li><a href="/negotiate-cart">Wishlist</a></li>
                        <li><a href="/admin/profile/edit-profile">Edit Profile</a></li>
                      </>
                    )}
                    {/* <li><a href = "#/" onClick = {(event) => { event.preventDefault() }}>Profile</a></li>
                    <li><a href = "#/" onClick = {(event) => { event.preventDefault() }}>Settings</a></li> */}
                    <li><a href="#/" onClick={(event) => { event.preventDefault(); props.logOut(); }}>Logout</a></li>
                  </ul>
                </div>
              </>
            )
            }
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}

export default Header;